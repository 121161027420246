import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles, Grid, Button, useMediaQuery, useTheme } from '@material-ui/core'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'
import { graphql } from 'gatsby'
import Axios from 'axios'
import classNames from 'classnames'
import { getZipFromSearch } from '@helpers/geo-location'
import { useApolloClient } from '@apollo/client'
import { fonts, breakPoints } from '@constants/styles'
import DeliveryInput from './DeliveryInput'

const useStyles = makeStyles(theme => ({
  gridContainer: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '10px',
    },
  },
  textContainer: ({ textColor }) => ({
    textAlign: 'center',
    '& h2': {
      color: textColor || theme.palette.common.white,
      fontWeight: 'bold',
      padding: '5rem 0 1rem 0',
      letterSpacing: '.3rem',
      [theme.breakpoints.down('sm')]: {
        letterSpacing: '0',
        padding: '25px 5px',
      },
    },
    '& p': {
      color: textColor || theme.palette.common.white,
      fontSize: `${fonts.txtMedium}`,
      padding: '0 0 4rem 0',
      letterSpacing: '.1rem',
      [theme.breakpoints.down('sm')]: {
        letterSpacing: '0.05rem',
        padding: '0 5px 25px',
      },
    },
  }),
  deliveryMessage: ({ textColor }) => ({
    minHeight: '5rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& p': {
      color: textColor || theme.palette.common.white,
      fontSize: `${fonts.txtMedium}`,
      letterSpacing: '.1rem',
      [theme.breakpoints.down('sm')]: {
        padding: '5px 10px',
        marginTop: '20px',
        width: 'calc(100vw - 30px)',
        marginBottom: '0',
      },
    },
    [`@media screen and (max-width: ${breakPoints.medium})`]: {
      flexDirection: 'column',
      textAlign: 'center',
    },
  }),
  button: {
    width: 'auto',
    backgroundColor: '#c4e5ff',
    color: '#07263b',
    borderRadius: '0',
    fontWeight: 'bold',
    padding: '0 20px 0 20px',
    marginLeft: '-10px',
    '&:hover': {
      color: '#c4e5ff',
    },
    '&:disabled': {
      backgroundColor: '#777c80',
      color: '#fff',
      opacity: 0.5,
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0 10px',
      marginLeft: '0',
    },
  },
  formContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  form: {
    width: '65%',
  },
  span: {
    display: 'flex',
    alignItems: 'center',
  },
  errorIcon: {
    margin: '0 10px 0 10px',
  },
  msgBackgroundColor: ({ backgroundColor }) => ({
    backgroundColor: backgroundColor || theme.palette.primary.dark,
    padding: '5px 10px',
    borderRadius: theme.shape.borderRadius,
    [theme.breakpoints.down('sm')]: {
      borderRadius: '0',
    },
  }),
  contentContainer: ({ backgroundImage, backgroundColor }) => ({
    minHeight: 'max-content',
    backgroundImage: `url(${backgroundImage})` || 'transparent',
    backgroundColor: backgroundColor || theme.palette.primary.dark,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  }),
}))

const DeliveryWidget = ({ data: strapiData, className = '', styles = {}, trackingData = null }) => {
  const data = strapiData.DeliveryWidget
  const [addressInput, setAddressInput] = useState('')
  const [showMessage, setShowMessage] = React.useState(false)
  const [deliveryMessage, setDeliveryMessage] = React.useState('')
  const [isLoading, setIsLoading] = useState(false)
  const apolloClient = useApolloClient()
  const isEmpty = !addressInput || !addressInput.trim()

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const classes = useStyles({
    textColor: data?.TextColor?.ColorHex,
    backgroundColor: data?.BackgroundColor?.ColorHex,
    backgroundImage:
      isMobile && data?.MobileBackgroundImage ? data?.MobileBackgroundImage?.url : data?.BackgroundImage?.url,
  })

  const onChange = event => {
    setAddressInput(event.target.value)
  }

  useEffect(() => {
    setShowMessage(false)
  }, [])
  useEffect(() => {}, [deliveryMessage])

  const handleSubmit = async event => {
    event.preventDefault()
    if (isEmpty) return
    setIsLoading(true)
    const isPRZip =
      (Number(addressInput) >= 600 && Number(addressInput) <= 799) ||
      (Number(addressInput) >= 900 && Number(addressInput) <= 999)

    /* explicitly define the allowed URLs */
    const allowedUSDevHosts = ['rtg-dev.com', 'www.rtg-dev.com']
    const allowedUSProdHosts = ['roomstogo.com', 'www.roomstogo.com']

    /* grab the domain from the URL */
    let windowHost = ''
    if (typeof window !== 'undefined') {
      windowHost = window.location.hostname
    }

    /* if the user is on .com dev or prod and enters a Puerto Rico zipcode, redirect to the PR website */
    if (allowedUSDevHosts.includes(windowHost) && isPRZip) {
      window.location.href = `https://www.rtg-dev.pr?zip=${Number(addressInput)}`
    } else if (allowedUSProdHosts.includes(windowHost) && isPRZip) {
      window.location.href = `https://www.roomstogo.pr?zip=${Number(addressInput)}`
    } else {
      const zip = await getZipFromSearch(apolloClient, addressInput)
      if (Object.is(zip, null)) {
        // Please enter valid US Address
        setDeliveryMessage(
          <span className={classNames(classes.span)}>
            <ErrorOutlineOutlinedIcon className={classNames(classes.errorIcon)} />
            Please enter a valid US City, State, or Zip Code.
          </span>,
        )
        setShowMessage(true)
      } else {
        const locationData = await Axios.get(`${process.env.GATSBY_LOCATION_SERVICE_URL}/?zip=${zip}`)
        const shippingZones = ['A', 'B', 'C', 'D', 'E', 'F', 'G']
        const canDeliver =
          !locationData.data.response.browse_only && shippingZones.includes(locationData.data.response.delivery_zone)
        const { state } = locationData.data.response
        // Check zip code first in case non-continental address is used (state is empty in this case)
        const decorOnly =
          (zip[0] !== '9' && state !== 'Utah') ||
          (!['Utah', 'Hawaii', 'Alaska', 'California'].includes(state) && state !== '')
        if (locationData) {
          if (canDeliver) {
            setDeliveryMessage(data?.SuccessText ?? 'Good news! You are in our delivery area!')
            setShowMessage(true)
          } else if (decorOnly) {
            setDeliveryMessage(
              data?.DecorOnlyText ??
                'Sorry, you are outside our delivery area. Some décor items, however, are available via UPS.',
            )
            setShowMessage(true)
          } else {
            setDeliveryMessage(data?.FailureText ?? 'Sorry, you are outside our delivery area.')
            setShowMessage(true)
          }
        }
      }
    }
    setIsLoading(false)
  }

  return (
    <div className={classes.contentContainer}>
      <Grid container>
        <Grid item xs={12}>
          <div className={classes.textContainer}>
            <h2>{data?.Title || 'CHECK TO SEE IF WE DELIVER TO YOUR LOCATION'}</h2>
            <p>{data?.SubLabel || 'Enter your City, State, or Zip Code to find out if we deliver to you.'}</p>
          </div>
        </Grid>
        <Grid item xs={12} className={classes.gridContainer}>
          <div className={classes.formContainer}>
            <form onSubmit={handleSubmit} className={classes.form}>
              <DeliveryInput
                placeholderText={data?.InputPlaceholder || 'City, State, or Zip Code'}
                addressInput={addressInput}
                onChange={onChange}
                noAutoComplete
                id="Header"
              />
            </form>
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              fullWidth
              size="large"
              type="submit"
              onClick={handleSubmit}
              disabled={isEmpty || isLoading}
            >
              {data?.ButtonText || 'Check Now'}
            </Button>
          </div>
        </Grid>

        <Grid item xs={12}>
          <div className={classes.deliveryMessage}>
            {showMessage && <p className={classes.msgBackgroundColor}>{deliveryMessage}</p>}
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

DeliveryWidget.propTypes = {
  data: PropTypes.object.isRequired,
  className: PropTypes.string,
  styles: PropTypes.object,
  trackingData: PropTypes.object,
}

export const StrapiButtonFragment = graphql`
  fragment DeliveryWidgetFragment on PageDeliveryWidget {
    id
    DeliveryWidget {
      Title
      HeadingLabel
      SubLabel
      ButtonText
      InputPlaceholder
      SuccessText
      FailureText
      BackgroundColor {
        ColorHex
      }
      TextColor {
        ColorHex
      }
      BackgroundImage {
        ...StrapiImageFragment
      }
      MobileBackgroundImage {
        ...StrapiImageFragment
      }
    }
  }
`
export default DeliveryWidget
